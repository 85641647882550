import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/SEO"
import PostSingle from "../components/Post/PostSingle"
import Search from "../components/Search"

const Post = props => {
  const { post, posts } = props.data
  const prevPost = props.pageContext.prev
  const nextPost = props.pageContext.next
  const {
    pageContext: {
      postSearchData: { allPosts, options },
    },
  } = props
  return (
    <Layout posts={allPosts} engine={options}>
      <Seo title={post.title} />
      {/* <Search posts={allPosts} engine={options} /> */}
      <PostSingle
        post={post}
        gallery={post.acfNewsEvents.imageGallery}
        allPosts={posts}
        prevPost={prevPost}
        nextPost={nextPost}
      />
    </Layout>
  )
}

export const query = graphql`
  query singlePostQuery($slug: String!) {
    post: wpPost(slug: { eq: $slug }) {
      title
      id
      date
      slug
      categories {
        nodes {
          name
        }
      }
      acfNewsEvents {
        mainContent
        needsRefresh
        imageGallery {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
          }
        }
        featuredImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1500)
            }
          }
        }
      }
    }

    posts: allWpPost {
      edges {
        node {
          title
          slug
          acfNewsEvents {
            needsRefresh
          }
        }
      }
    }
  }
`

export default Post
