import React from "react"
import styled from "styled-components"
import { standardWrapper } from "../../styles/helpers"

import PostHeader from "./PostHeader"
import PostFeaturedImage from "./PostFeaturedImage"
import PostWysiwyg from "./PostWysiwyg"
import PostGallery from "./PostGallery"
import PostNav from "./PostNav"

const PostSingle = ({ post, gallery, allPosts, prevPost, nextPost }) => {
  return (
    <>
      <PostSingleArticle>
        <div className="wrapper">
          <PostHeader
            title={post.title}
            date={post.date}
            categories={post.categories}
          />
          <PostFeaturedImage image={post.acfNewsEvents.featuredImage} />
          <PostWysiwyg content={post.acfNewsEvents.mainContent} />
        </div>
      </PostSingleArticle>
      {gallery?.length > 0 ? <PostGallery gallery={gallery} /> : null}
      <PostNav allPosts={allPosts} prevPost={prevPost} nextPost={nextPost} />
    </>
  )
}

const PostSingleArticle = styled.article`
  .wrapper {
    ${standardWrapper};
    @media (min-width: 1025px) {
      max-width: 95rem !important;
    }
  }
`

export default PostSingle
