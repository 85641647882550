import React, { useState, useRef } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import PostLightbox from "./PostLightbox"
import { colors } from "../../styles/helpers"

const settingsGallery = {
  fade: false,
  draggable: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 15000,
  centerPadding: "350px",
  centerMode: false,
  arrows: true,
  dots: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        centerPadding: "100px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        centerPadding: "0px",
        slidesToShow: 1,
      },
    },
  ],
}

const PostGallery = ({ gallery }) => {
  const slickSliderGallery = useRef(null)
  const [lightboxActive, setLightboxActive] = useState(false)
  const [indexActive, setIndexActive] = useState(0)

  const handleSetLightboxActive = event => {
    const currentSlideIndex = event.target.classList.contains("slick-slide")
      ? event.target.dataset.index
      : event.target.parentElement.classList.contains("slick-slide")
      ? event.target.parentElement.dataset.index
      : event.target.parentElement.parentElement.classList.contains(
          "slick-slide"
        )
      ? event.target.parentElement.parentElement.dataset.index
      : event.target.parentElement.parentElement.parentElement.classList.contains(
          "slick-slide"
        )
      ? event.target.parentElement.parentElement.parentElement.dataset.index
      : event.target.parentElement.parentElement.parentElement.parentElement.classList.contains(
          "slick-slide"
        )
      ? event.target.parentElement.parentElement.parentElement.parentElement
          .dataset.index
      : event.target.parentElement.parentElement.parentElement.parentElement.parentElement.classList.contains(
          "slick-slide"
        )
      ? event.target.parentElement.parentElement.parentElement.parentElement
          .parentElement.dataset.index
      : 1

    setIndexActive(currentSlideIndex)
    setLightboxActive(!lightboxActive)
  }

  const handleCloseLightBox = () => {
    setLightboxActive(false)
  }

  if (gallery === null) return null
  if (gallery.length === 0) return null

  return (
    <StyledDiv>
      <div className="gallery-wrapper">
        <Slider ref={slickSliderGallery} {...settingsGallery}>
          {gallery.map((image, index) => {
            const imageDisplay = getImage(
              image.localFile.childImageSharp.gatsbyImageData
            )
            const imageAltText = getImage(image.altText)
            return (
              <GalleryImage
                key={index}
                onClick={event => handleSetLightboxActive(event)}
              >
                <GatsbyImage
                  image={imageDisplay}
                  alt={imageAltText ? imageAltText : ""}
                  layout="fixed"
                />
              </GalleryImage>
            )
          })}
        </Slider>
      </div>
      {lightboxActive && (
        <PostLightbox
          lightboxActive={lightboxActive}
          handleCloseLightBox={handleCloseLightBox}
          indexActive={indexActive}
          gallery={gallery}
        />
      )}
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  width: 100%;
  .gallery-wrapper {
    width: 100%;
  }

  .slick-arrow {
    position: absolute !important;
    top: calc(50% + 2rem);
    width: 50px;
    height: 50px;
    z-index: 100;

    &::before {
      color: ${colors.colorSecondary};
      font-size: 50px;
    }
  }

  .slick-prev {
    left: 5rem;

    @media (min-width: 768px) {
      left: 2%;
    }
  }

  .slick-next {
    right: 5rem;

    @media (min-width: 768px) {
      right: 2%;
    }
  }
`

const GalleryImage = styled.div`
  position: relative;
  height: 35rem;
  z-index: 10;
  cursor: zoom-in;

  @media (min-width: 768px) {
    height: 45rem;
    margin-right: 2rem;
    margin-left: 2rem;
  }

  @media (min-width: 1025px) {
  }

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    right: 1rem;
    left: 1rem;
    height: 100%;
  }
`

export default PostGallery
